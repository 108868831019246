import React from "react"
import { Link } from "gatsby"
import { Box, Styled } from "theme-ui"
import Logo from "./logo"

/*
 * Esta es la forma extra abreviada de declarar un componente.
 * No lleva ni nombre de componente.
 * Fijate que no usa {} por que no hay variables en el componente.
 * Fijate que no usa return.
 * Mira comentarios en archivo layout
 *
 */
export default ( props ) => {

  let isFrontPage = ( props.page === 'frontpage' ) ? true : false

  return(
    <Box
      as={`header`}
      sx={{
        bg: `black`,
        pt: 4,
        pb: [4, 4, 4, 0, 0],
        px: 3,
        a: {
          borderBottom: 0,
          "&:hover": {
            borderBottom: 0,
          },
        },
        p: {
          mb: 0,
        },
        "h1, h2, h3, p, ul, li, label, input": {
          color: `white`,
          fontSize: 0,
        },
        ".nav-link": {
          borderBottom: theme => `1px dotted ${theme.colors.primary}`,
          fontSize: 0,
          display: `inline-block`,
          my: [3, 3, 3, 4, 4],
          py: 1,
          "&:hover": {
            borderBottom: theme => `1px solid ${theme.colors.primary}`,
          },
        },
        ".nav": {
          fontSize: 0,
          position: [`inherit`, `inherit`,`inherit`, `absolute`, `absolute`],
          bottom: 2,
          left: 3,
        },
      }}
    >
      {props.children}
      { isFrontPage &&
        <div>
          <Styled.p>Un proyecto de<br/><Styled.a href="https://fundacionbibliotecasocial.org">Fundación Biblioteca Social</Styled.a></Styled.p><Styled.a href="https://fundacionbibliotecasocial.org"><Logo/></Styled.a>
        </div>
      }
      <Box as="div" className="nav">
        { isFrontPage &&
          <Styled.a href="http://mapes.fundacionbibliotecasocial.org" className="nav-link" rel="nofollow" hrefLang="ca" lang="ca">Català &#x02197;</Styled.a>
        }
        {!isFrontPage &&
          <Styled.a as={Link} to="/" className="nav-link" aria-label="inici">&#x02190; Inicio</Styled.a>
        }
      </Box>
    </Box>
  )

}